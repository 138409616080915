<template>
    <v-container class="mt-6">
        <center>
            <h2 class="hidden-sm-and-down title-style mt-7">Create Multipurpose Assistants super fast.</h2>
            <h2 class="hidden-md-and-up title-style-mb mt-3">Create Multipurpose Assistants super fast.</h2>
            <v-row class="mt-13">
                <v-col>
                    <v-card style="width: 350px; background-color: transparent;" elevation="0">
                        <v-row>
                            <v-col style="max-width: 70px; text-align: right" class="mr-3">
                                <v-icon color="white" size="40">mdi-handshake-outline</v-icon>
                            </v-col>
                            <v-divider vertical color="white"></v-divider>
                            <v-col style="text-align: left;" class="ml-3">
                                <p style="color:white; font-size: larger; font-weight: 500;">For Sales</p>
                                <p style="color:white;">Accurate recommendations to your customers. ChatGPT now is the
                                    expert of your Business.
                                </p>
                            </v-col>
                        </v-row>
                    </v-card>
                </v-col>
                <v-col>
                    <v-card style="width: 350px; background-color: transparent;" elevation="0">
                        <v-row>
                            <v-col style="max-width: 70px; text-align: right" class="mr-3">
                                <v-icon color="white" size="40">mdi-auto-fix</v-icon>
                            </v-col>
                            <v-divider vertical color="white"></v-divider>
                            <v-col style="text-align: left;" class="ml-3">
                                <p style="color:white; font-size: larger; font-weight: 500;">For Support CX</p>
                                <p style="color:white;">Solve any doubts of your clients or teammates.</p>
                            </v-col>
                        </v-row>
                    </v-card>
                </v-col>
                <v-col>
                    <v-card style="width: 350px; background-color: transparent;" elevation="0">
                        <v-row>
                            <v-col style="max-width: 70px; text-align: right" class="mr-3">
                                <v-icon color="white" size="40">mdi-rocket-outline</v-icon>
                            </v-col>
                            <v-divider vertical color="white"></v-divider>
                            <v-col style="text-align: left;" class="ml-3">
                                <p style="color:white; font-size: larger; font-weight: 500;">For Anything</p>
                                <p style="color:white;">Truly your imagination is the limit.</p>
                            </v-col>
                        </v-row>
                    </v-card>
                </v-col>
            </v-row>
            <!-- <video src="https://d153chakadu90d.cloudfront.net/web/multisource_video_.webm"
                poster="https://d153chakadu90d.cloudfront.net/web/chatpro_tumbnail.svg"
                 loop autoplay muted>
                Your browser does not support the video tag.
            </video> -->


            <video playsinline autoplay muted loop style="width: 100%; border-radius: 15px;" class="mt-15"> 
            <source src="https://d153chakadu90d.cloudfront.net/web/multisource_video_.webm" type="video/webm">
            <source src="https://d153chakadu90d.cloudfront.net/web/multisource_video_mp4.mp4" type="video/mp4">
            Your browser does not support the video tag.
            </video>
        </center>
        <v-card class="mx-auto mt-16 glass-card hidden-sm-and-down" elevation="3" outlined>
            <v-row class="mb-8">
                <v-col class="ml-10">
                    <h2 style="color: white; font-weight: 600; font-size: 30px;" class="mt-13">Train and <span
                            style="background-color: #7579e7; border-radius: 7px;"><u>Mix</u> multiple Data Sources</span>.
                    </h2>
                    <p style="color: white;" class="mt-10 p-text-pc">With ChatPro AI you can MIX UP multiple data sources,
                        YouTube Videos, PDFs, whatever you want!</p>

                    <p style="color: white;" class="mt-10 p-text-pc">We're constantly adding new data sources so you can
                        make your AI Assistants smarter.</p>
                    <v-btn @click="goToRegister()" rounded class="mt-9">
                        Create <v-icon right>mdi-chevron-right</v-icon>
                    </v-btn>
                </v-col>
                <v-col style="max-width: 450px;">
                    <v-img contain src="https://d153chakadu90d.cloudfront.net/web/multiple_sources.webp" height="110%"
                        alt="Custom ChatGPT chat for business example 2"></v-img>
                </v-col>
            </v-row>
        </v-card>

        <v-card class="mx-auto mx-1 mt-16 glass-card  hidden-md-and-up" elevation="3" outlined>
            <v-row class="mt-3">
                <v-img contain src="https://d153chakadu90d.cloudfront.net/web/multiple_sources.webp" width="10px"
                    alt="Custom ChatGPT chat for business example 2"></v-img>
            </v-row>
            <v-row>
                <h2 style="color: white; font-weight: 600; font-size: 25px;" class="ml-5 mt-3">Train and <u>Mix</u><span style="background-color: #7579e7; border-radius: 7px;"><br> multiple Data Sources</span>.</h2>
                <p style="color: white;" class="ml-5 mt-3">With ChatPro AI you can MIX UP multiple data sources,
                        YouTube Videos, PDFs, whatever you want!</p>

                <p style="color: white;" class="ml-5 mr-2">We're constantly adding new data sources so you can make your AI Assistants smarter.</p>

                <v-btn @click="goToRegister()" rounded class="mt-5 mb-10 ml-5">
                    Create <v-icon right>mdi-chevron-right</v-icon>
                </v-btn>
            </v-row>
        </v-card>

        <countries />

    </v-container>
</template>

<script>

// @ is an alias to /src  
import countries from '@/components/components_landing_home/userCountries.vue'
export default {
    name: 'MultipurposeView',
    components: {
        countries
    },
    methods: {
        goToRegister() {
            this.$router.push("/Register") //redirecciona a chatpro register
        },
    }
}
</script>

<style scoped>
.p-text-pc {
    font-size: 1.2rem;
}

.glass-card {
    border: none;
    border-radius: 20px !important;
    padding: 20px;
    background-color: rgba(255, 255, 255, 0.1);
    width: 1100px;
    border-image: linear-gradient(45deg, rgb(198, 15, 15), black) 1;

}

.glass-card::before {
    content: "";
    position: absolute;
    top: -2px;
    left: -2px;
    right: -2px;
    bottom: -2px;
    border-image: linear-gradient(45deg, rgb(198, 15, 15), black) 1;
    border-radius: 20px;
}

.v-btn {
    text-transform: none;
}

.title-style {
    color: #ffffff;
    font-size: 35px;
    line-height: 45px;
    text-align: center;
    font-weight: 600;
}

.title-style-mb {
    color: #ffffff;
    font-size: 28px;
    line-height: 45px;
    text-align: center;
    font-weight: 600;
}

.intro-text-head-desc {
    color: white;
    font-size: 20px;
    font-weight: 400;
}

.intro-text-head-desc-mb {
    color: white;
    font-size: 15px;
    font-weight: 400;
    text-align: center;
}
</style>
