<template>
    <v-container fluid>
        <center>
            <v-btn class="mb-2" rounded color="#7579E7" outlined><v-icon left>mdi-creation</v-icon>7 Days FREE Trial</v-btn>
            <v-card style="border-radius: 15px; height: 505px; width: 370px;">
                    <v-card-title class="ml-2" style="font-size: medium;">Customize your plan</v-card-title>
                    <h1 class="ml-7 mb-3" style="font-weight: 800; font-size: 40px; text-align: left;">
                        {{ plans[planSelected].price }}<span style="font-size: small; font-weight: lighter;">/month</span>
                    </h1>
                    <v-slider class="mx-5"  track-color="#00000030" v-model="planSelected" color="black" :tick-labels="ticksLabels" :max="3" step="1" ticks="always" tick-size="4" ></v-slider>
                    <v-list-item three-line class="mt-4">
                        <v-list-item-content class="ml-2" style="text-align: left;">
                            <v-list-item-title class="mb-2">
                                <v-icon left size="15" color="#25D366">mdi-checkbox-marked-circle-outline</v-icon>
                                <span style="font-size: small;">{{ ticksLabels[planSelected] }} credits/month</span>
                            </v-list-item-title>
                            <v-list-item-title v-for="(feature, fIndex) in plans[1].features" :key="fIndex" class="mb-2">
                                <v-icon left size="15" color="#25D366">mdi-checkbox-marked-circle-outline</v-icon>
                                <span style="font-size: small;">{{ feature }}</span>
                            </v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                    <v-card-actions class="pt-5">
                        <v-btn @click="subscribePlan(planSelected)" rounded color="black" block
                            class="white--text">Start FREE Trial</v-btn>
                    </v-card-actions>
                    <p style="font-size: x-small;">Cancel at anytime within the Free trial.</p>
                </v-card>
        </center>
    </v-container>
</template>

<script>

// @ is an alias to /src  

export default {
    name: 'HomeView',
    components: {
    },
    data() {
        return {

            planSelected: 0,

            ticksLabels: [
                '2,000',
                '5,000',
                '10,000',
                '40,000'
            ],
            plans: [
                {
                    title: 'Starter',
                    price: '$19',
                    features: [
                        'Unlimited ChatGPT Assistants',
                        'Embed on unlimited websites',
                        'Upload multiple files',
                        'Unlimited Storage*',
                        'Full Conversation history',
                        'Capture leads'
                    ]
                },
                {
                    title: 'Plus',
                    price: '$47',
                    features: [
                        'Unlimited ChatGPT Assistants',
                        'Embed on unlimited websites',
                        'Upload multiple files',
                        'Unlimited Storage*',
                        'Full Conversation history',
                        'Capture leads'
                    ]
                },
                {
                    title: 'Premium',
                    price: '$99',
                    features: [
                        'Unlimited ChatGPT Assistants',
                        'Embed on unlimited websites',
                        'Upload multiple files',
                        'Unlimited Storage*',
                        'Full Conversation history',
                        'Capture leads'
                    ]
                },
                {
                    title: 'Ultimate',
                    price: '$399',
                    features: [
                        'Unlimited ChatGPT Assistants',
                        'Embed on unlimited websites',
                        'Upload multiple files',
                        'Unlimited Storage*',
                        'Full Conversation history',
                        'Capture leads'
                    ]
                }
            ],
            freeButtonDisabled: false
        };
    },

    methods: {
        subscribePlan(plan) {
            if(plan == 0){
                this.freeButtonDisabled = true;
            }

            console.log("User select plan " + plan)
            this.$emit('variable-plan', plan);
        }
    }
}
</script>

<style scoped>
.v-btn {
    text-transform: none;
}

.intro-text-head {
    color: #000000;
    font-size: 900;
    font-weight: 550;
    line-height: 65px;
    text-align: left;
}

.intro-text-head-mb {
    color: #000000;
    font-size: 900;
    font-weight: 550;
    line-height: 45px;
    text-align: left;

}

.intro-text-head-desc {
    color: rgb(0, 0, 0);
    font-size: 18px;
    font-weight: 400;
    text-align: left;
}

.intro-text-head-desc-mb {
    color: rgb(0, 0, 0);
    font-size: 15px;
    font-weight: 400;
    text-align: left;
}

.glass-card {
    border: none;
    background-color: rgba(255, 255, 255, 0.431);
}

.glass-card::before {
    content: "";
    position: absolute;
    top: -2px;
    left: -2px;
    right: -2px;
    bottom: -2px;
    border-image: linear-gradient(45deg, rgb(255, 255, 255), black) 1;
    border-radius: 20px;
}
</style>

