<template>
    <div>
        <!--Medida más pequeña 360x740-->
        <v-app-bar class="top hidden-md-and-up mt-3 ml-1 mr-1" app style="background: rgba(255, 255, 255, 0.1);
    -webkit-backdrop-filter: blur(13px);
    backdrop-filter: blur(13px);
    border: 1px solid rgba(255, 255, 255, 0.05); border-radius: 15px;" elevation="0" height="75">
            <v-app-bar-nav-icon dark @click.stop="dialogMenu = true"></v-app-bar-nav-icon>

            <v-img src="https://d153chakadu90d.cloudfront.net/web/white_chatpro_logo.webp" class="ml-2"
                @click="redirectToHome()" max-height="120" max-width="120" alt="ChatGPT ChatPro Logo AI"></v-img>

            <v-spacer></v-spacer>

            <v-btn @click="goToRegister()" rounded color="white" class="mr-2">
                Register <v-icon right>mdi-chevron-right</v-icon>
            </v-btn>
        </v-app-bar>

        <!--PC-->
        <v-app-bar class="hidden-sm-and-down mt-3 ml-5 mr-5" app style="background: rgba(255, 255, 255, 0.1);
    -webkit-backdrop-filter: blur(13px);
    backdrop-filter: blur(13px);
    border: 1px solid rgba(255, 255, 255, 0.05); border-radius: 20px;" elevation="0" height="75">
            <v-img class="ml-7" src="https://d153chakadu90d.cloudfront.net/web/white_chatpro_logo.webp"
                @click="redirectToHome()" max-height="150" max-width="150" contain alt="ChatGPT ChatPro Logo AI"></v-img>
            <v-spacer></v-spacer>
            <v-list class="d-flex align-center" style="background-color: transparent">
                <v-list-item class="mr-15">
                    <a @click="goToPricing()">
                        <v-list-item-title
                            style="font-size: 17px; color: white; margin-right: 80px;">Pricing</v-list-item-title>
                    </a>
                    <a :href="'https://app.chatpro.pro/login'" style="text-decoration: none;">
                        <v-list-item-title style="font-size: 17px; color: white">Log in</v-list-item-title>
                    </a>
                </v-list-item>
            </v-list>
            <v-btn @click="goToRegister()" rounded color="white" class="mr-10">
                Register <v-icon right>mdi-chevron-right</v-icon>
            </v-btn>
        </v-app-bar>
        <!--MENU MOBILE-->
        <v-dialog v-model="dialogMenu" width="400" transition="dialog-top-transition">
            <v-card class="glass-card" elevation="10" outlined style="height: 350px; ">
                <center>
                    <p style="font-size: 25px; font-weight: 450; color: white;" class="mx-auto">Menu</p>
                    <v-divider color="white"></v-divider>
                </center>
                <v-btn @click="goToRegister()" block depressed big color="black" class="mt-10" dark x-large rounded>
                    Register
                </v-btn>
                <a href="https://app.chatpro.pro/login" style="text-decoration: none;">
                    <v-btn block depressed big color="black" dark class="mt-3" x-large rounded>
                        Log In
                    </v-btn>
                </a>
                <a @click="goToPricing()" style="text-decoration: none;">
                    <v-btn block depressed color="black" dark class="mt-3" x-large rounded>
                        Pricing
                    </v-btn>
                </a>
            </v-card>
        </v-dialog>

    </div>
</template>

<script>
//import HelloWorld from "./components/HelloWorld";

export default {
    name: "App",
    data() {
        return {
            dialogMenu: false,
        }
    },

    methods: {

        redirectToHome() {
            this.$router.push("/")
        },
        goToRegister() {
            this.$router.push("/Register")
        },

        goToPricing() {
            this.$router.push("/pricing")
        },
    }
};
</script>

<style scoped>
.v-btn {
    text-transform: none;
}

.glass-card {
    border-radius: 16px !important;
    border-color: white;
    padding: 20px;
    background-color: rgb(7, 0, 0);
}
</style>