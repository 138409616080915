<template>
    <div>
        <v-container style="margin-top: 130px;  text-align: center;">
            <center>
                <h2 class="intro-text-head hidden-sm-and-down">Unlock Your <u>Business Potential</u> with Our Plans</h2>
                <h2 class="intro-text-head-mb hidden-md-and-up">Unlock Your <u>Business Potential</u> with Our Plans</h2>
                <p class="intro-text-head-desc mt-2 hidden-sm-and-down">Whether you're a startup or a growing business,
                    we've got you covered.
                    Choose your own plan, designed to meet your needs and exceed your
                    expectations. Experience the power of <u>ChatGPT</u> today.</p>
                <p class="intro-text-head-desc-mb mt-8 hidden-md-and-up ml-2 mr-2" style="text-align: justify;">Whether
                    you're a startup or a growing business, we've got you covered.
                    Choose from our plans, designed to exceed your expectations.</p>
            </center>
        </v-container>
        <v-container fluid>
            <center>
                <v-btn class="mb-2" rounded color="#7579E7" outlined><v-icon left>mdi-creation</v-icon>7 Days FREE Trial</v-btn>
                <v-card style="border-radius: 15px; height: 505px; width: 370px;">
                   
                    <v-card-title class="ml-2" style="font-size: medium;">Customize your plan</v-card-title>
                    <h1 class="ml-7 mb-3" style="font-weight: 800; font-size: 40px; text-align: left;">
                        {{ plans[plan].price }}<span style="font-size: small; font-weight: lighter;">/month</span>
                    </h1>
                    <v-slider class="mx-5"  track-color="#00000030" v-model="plan" color="black" :tick-labels="ticksLabels" :max="3" step="1" ticks="always" tick-size="4" ></v-slider>
                    <v-list-item three-line class="mt-4">
                        <v-list-item-content class="ml-2" style="text-align: left;">
                            <v-list-item-title class="mb-2">
                                <v-icon left size="15" color="#25D366">mdi-checkbox-marked-circle-outline</v-icon>
                                <span style="font-size: small;">{{ ticksLabels[plan] }} credits/month</span>
                            </v-list-item-title>
                            <v-list-item-title v-for="(feature, fIndex) in plans[1].features" :key="fIndex" class="mb-2">
                                <v-icon left size="15" color="#25D366">mdi-checkbox-marked-circle-outline</v-icon>
                                <span style="font-size: small;">{{ feature }}</span>
                            </v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                    <v-card-actions class="pt-5">
                        <v-btn @click="subscribePlan(index)" rounded color="black" block
                            class="white--text">Start FREE Trial</v-btn>
                    </v-card-actions>
                    <p style="font-size: x-small;">Cancel at anytime within the Free trial.</p>

                </v-card>
            </center>

        </v-container>
        <center>
            <v-card style="width: fit-content; border-radius: 10px; margin-bottom: 200px;" class="mt-9 glass-card">
                <v-row>
                    <v-col style="max-width:20%;">
                        <v-img contain style="width: 25px;" class="ml-3"
                            src="https://upload.wikimedia.org/wikipedia/commons/thumb/0/04/ChatGPT_logo.svg/1024px-ChatGPT_logo.svg.png"></v-img>
                    </v-col>
                    <v-col style="text-align: left;">
                        <span style="font-size: small;">Powered by ChatGPT</span>
                    </v-col>
                </v-row>
            </v-card>
        </center>
    </div>
</template>

<script>
/* eslint-disable no-undef */
export default {
    name: 'HomeView',
    components: {},
    data() {
        return {

            plan: 0,

            ticksLabels: [
                '2,000',
                '5,000',
                '10,000',
                '40,000',
            ],
            plans: [
                {
                    title: 'Starter',
                    price: '$19',
                    features: [
                        'Unlimited ChatGPT Assistants',
                        'Embed on unlimited websites',
                        'Upload multiple files',
                        'Unlimited Storage*',
                        'Full Conversation history',
                        'Capture leads'
                    ]
                },
                {
                    title: 'Plus',
                    price: '$47',
                    features: [
                        'Unlimited ChatGPT Assistants',
                        'Embed on unlimited websites',
                        'Upload multiple files',
                        'Unlimited Storage*',
                        'Full Conversation history',
                        'Capture leads'
                    ]
                },
                {
                    title: 'Premium',
                    price: '$99',
                    features: [
                        'Unlimited ChatGPT Assistants',
                        'Embed on unlimited websites',
                        'Upload multiple files',
                        'Unlimited Storage*',
                        'Full Conversation history',
                        'Capture leads'
                    ]
                },
                {
                    title: 'Ultimate',
                    price: '$399',
                    features: [
                        'Unlimited ChatGPT Assistants',
                        'Embed on unlimited websites',
                        'Upload multiple files',
                        'Unlimited Storage*',
                        'Full Conversation history',
                        'Capture leads'
                    ]
                }
            ]
        };
    },
    methods: {
        subscribePlan(plan) {
            console.log("El usuario seleccionó el plan " + plan);
            this.$router.push("/Register")
        }
    }
}
</script>

<style scoped>
.v-btn {
    text-transform: none;
}

.intro-text-head {
    color: #ffffff;
    font-size: 40px;
    line-height: 65px;
    text-align: center;
    font-weight: 600;
}

.intro-text-head-mb {
    color: #ffffff;
    font-size: 37px;
    line-height: 45px;
    font-weight: 600;

}

.intro-text-head-desc {
    color: white;
    font-size: 18px;
    font-weight: 400;
}

.intro-text-head-desc-mb {
    color: white;
    font-size: 15px;
    font-weight: 400;
    text-align: center;
}

.glass-card {
    border: none;
    background-color: rgba(255, 255, 255, 0.431);
}

.glass-card::before {
    content: "";
    position: absolute;
    top: -2px;
    left: -2px;
    right: -2px;
    bottom: -2px;
    border-image: linear-gradient(45deg, rgb(255, 255, 255), black) 1;
    border-radius: 20px;
}
</style>

