<template>
    <v-container class="mt-6">
        <v-card class="mx-auto mt-2 glass-card hidden-sm-and-down" elevation="3" outlined>
            <v-row class="mb-3">
                <v-col style="max-width: 450px;">
                    <v-img contain class="mt-8" src="https://d153chakadu90d.cloudfront.net/web/caselegal_3.webp"
                        height="90%" alt="Custom ChatGPT chat for business example 1"></v-img>
                </v-col>
                <v-col>
                    <h2 style="color: white; font-weight: 600; font-size: 30px;" class="mt-13">Your data is safe with
                        us.
                    </h2>
                    <p style="color: white;" class="mt-10 mr-6 p-text-pc">We store all your raw data in secure clusters
                        from
                        the
                        world's largest cloud providers, AWS (Amazon Web Services) and GCP (Google Cloud Platform). </p>

                    <v-btn @click="goToRegister()" rounded class="mt-9">
                        Start <v-icon right>mdi-chevron-right</v-icon>
                    </v-btn>
                </v-col>
            </v-row>
        </v-card>

        <v-card class="mx-auto mx-1 mt-8 glass-card  hidden-md-and-up" elevation="3" outlined>
            <v-row class="mt-3">
                <v-img contain src="https://d153chakadu90d.cloudfront.net/web/caselegal_3.webp" width="10px"
                    alt="Custom ChatGPT Assistant chat for business"></v-img>
            </v-row>
            <v-row>
                <h2 style="color: white; font-weight: 600; font-size: 25px;" class="ml-5 mt-3">Your data is safe with
                    us.
                </h2>
                <p style="color: white;" class="ml-5 mt-3">We store all your raw data in secure clusters from the
                    world's
                    largest cloud providers, AWS (Amazon Web Services) and GCP (Google Cloud Platform).
                </p>

                <v-btn @click="goToRegister()" rounded class="mt-5 mb-10 ml-5">
                    View more <v-icon right>mdi-chevron-right</v-icon>
                </v-btn>

            </v-row>
        </v-card>

        <!-- <h2 style="color: white; font-weight: 600; font-size: 30px;" class="mt-2 mb-7 hidden-sm-and-down">A powerful
                platform to keep an eye on your AI Assistants team.</h2>
            <h2 style="color: white; font-weight: 600; font-size: 22px;" class="mt-2 mb-7 hidden-md-and-up">A powerful
                platform to keep an eye on your AI Assistants team.</h2>
            <v-img contain src="https://d153chakadu90d.cloudfront.net/web/chatgpt_platform_preview.png" height="110%"
                alt="ChatPro Platform for create personalized ChatGPT Chatbots"></v-img> -->
        <center>
            <v-card rounded class="mb-12 mt-15 hidden-sm-and-down" color="black"
                style="width: 800px; height: 170px; border-radius: 20px !important;">
                <v-parallax src="https://d153chakadu90d.cloudfront.net/web/parallax_test_2.png" height="170">
                    <v-row>
                        <v-col style="text-align: left;" class="ml-10">
                            <h2 style="color: white; font-weight: 600; font-size: 30px;" class="my-3">Take an advantage for
                                your Business with ChatGPT.</h2>
                        </v-col>
                        <v-col style="padding-top: 70px;" class="ml-10">

                            <v-btn @click="goToRegister()" rounded>
                                Start now<v-icon right>mdi-chevron-right</v-icon>
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-parallax>
            </v-card>

            <v-card rounded class="mb-12 mt-15 hidden-md-and-up" color="black"
                style="width: 800px; height: 180px; border-radius: 20px !important;">
                <v-parallax src="https://d153chakadu90d.cloudfront.net/web/parallax_test_2.png" height="180">
                    <v-row class="mt-6">
                        <h2 style="color: white; font-weight: 600; font-size: 22px;" class="mx-3">Take an advantage for your
                            Business with ChatGPT.</h2>
                    </v-row>
                    <v-row class="mx-auto">

                        <v-btn @click="goToRegister()" rounded>
                            Start now<v-icon right>mdi-chevron-right</v-icon>
                        </v-btn>
                    </v-row>
                </v-parallax>
            </v-card>



        </center>
    </v-container>
</template>

<script>

// @ is an alias to /src  
// 

export default {
    name: 'MultipurposeView',
    components: {
    },
    methods: {
        goToRegister() {
            this.$router.push("/Register") //redirecciona a chatpro landing
        },
    }
}
</script>

<style scoped>
.glass-card {
    border: none;
    border-radius: 20px !important;
    padding: 20px;
    background-color: rgba(255, 255, 255, 0.1);
    width: 1100px;
    border-image: linear-gradient(45deg, rgb(198, 15, 15), black) 1;

}

.glass-card::before {
    content: "";
    position: absolute;
    top: -2px;
    left: -2px;
    right: -2px;
    bottom: -2px;
    border-image: linear-gradient(45deg, rgb(198, 15, 15), black) 1;
    border-radius: 20px;
}


.v-btn {
    text-transform: none;
}

.title-style {
    color: #ffffff;
    font-size: 35px;
    line-height: 45px;
    text-align: center;
    font-weight: 600;
}

.title-style-mb {
    color: #ffffff;
    font-size: 28px;
    line-height: 45px;
    text-align: center;
    font-weight: 600;
}

.intro-text-head-desc {
    color: white;
    font-size: 20px;
    font-weight: 400;
}

.intro-text-head-desc-mb {
    color: white;
    font-size: 15px;
    font-weight: 400;
    text-align: center;
}
</style>
