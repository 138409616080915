<template>
    <div>
        <v-app>
            <Header />
            <v-main
                style="padding: 0px 0px 0px; background-image: linear-gradient(to bottom, #000000, #000000,  #000000, #000000,  #636363, #ffffff);">
                <v-container style="margin-top: 130px; margin-bottom: 200px; text-align: center;">
                    <center>
                        <h2 class="intro-text-head hidden-sm-and-down">Privacy policy</h2>
                        <h2 class="intro-text-head-mb hidden-md-and-up">Privacy policy</h2>

                        <v-card class="glass-card mt-12" style="border-radius: 20px; ">
                            <br>
                            <v-row>
                                <v-col class="ml-8 mr-8">
                                    <p style="color: white; text-align: justify;">At ChatPro AI, accessible from
                                        https://chatpro.pro, one of our main priorities is the privacy of our visitors.
                                        This Privacy Policy document contains types of information that is collected and
                                        recorded by ChatPro AI and how we use it.
                                        If you have additional questions or require more information about our Privacy
                                        Policy, do not hesitate to contact us.
                                        This Privacy Policy applies only to our online activities and is valid for visitors
                                        to our website with regards to the information that they shared and/or collect in
                                        ChatPro AI. This policy is not applicable to any information collected offline or
                                        via channels other than this website.</p>

                                    <h2 style="color: white; font-weight: 600; text-align: left;" class="mb-5">Consent</h2>
                                    <p style="color: white; text-align: justify;">By using our website, you hereby consent
                                        to our Privacy Policy and agree to its terms.</p>

                                    <h2 style="color: white; font-weight: 600; text-align: left;" class="mb-5">Information
                                        we collect</h2>
                                    <p style="color: white; text-align: justify;">The personal information that you are
                                        asked to provide, and the reasons why you are asked to provide it, will be made
                                        clear to you at the point we ask you to provide your personal information.
                                        If you contact us directly, we may receive additional information about you such as
                                        your name, email address, the contents of the message and/or attachments you may
                                        send us, and any other information you may choose to provide.
                                        When you register for an Account, we may ask for your contact information, including
                                        items such as name/company name, email address, industry and country.
                                    </p>

                                    <h2 style="color: white; font-weight: 600; text-align: left;" class="mb-5">How we use
                                        your information</h2>
                                    <p style="color: white; text-align: justify;">
                                        We use the information we collect in various ways, including to:
                                        <br>
                                        <br>
                                    <ul>
                                        <li>Provide, operate, and maintain our website.</li>
                                        <li>Improve, personalize, and expand our website.</li>
                                        <li>Understand and analyze how you use our website.</li>
                                        <li>Develop new products, services, features, and functionality.</li>
                                        <li>Communicate with you, either directly or through one of our partners, including
                                            for customer service, to provide you with updates and other information relating
                                            to the website, and for marketing and promotional purposes.</li>
                                        <li>Send you emails</li>
                                        <li>Find and prevent fraud</li>
                                    </ul>
                                    </p>

                                    <h2 style="color: white; font-weight: 600; text-align: left;" class="mb-5">GDPR Data
                                        Protection Rights</h2>
                                    <p style="color: white; text-align: justify;">
                                        We would like to make sure you are fully aware of all of your data protection
                                        rights. Every user is entitled to the following:
                                        <br>
                                        <br>
                                    <ul>
                                        <li><b>The right to access:</b> You have the right to request copies of your
                                            personal
                                            data.</li>
                                        <li><b>The right to rectification:</b> You have the right to request that we correct
                                            any
                                            information you believe is inaccurate. You also have the right to request that
                                            we complete the information you believe is incomplete.</li>
                                        <li><b>The right to erasure:</b> You have the right to request that we erase your
                                            personal
                                            data, under certain conditions.</li>
                                        <li><b>The right to restrict processing:</b> You have the right to request that we
                                            restrict
                                            the processing of your personal data, under certain conditions.</li>
                                        <li><b>The right to object to processing:</b> You have the right to object to our
                                            processing
                                            of your personal data, under certain conditions.</li>
                                        <li><b>The right to data portability:</b> You have the right to request that we
                                            transfer
                                            the data that we have collected to another organization, or directly to you,
                                            under certain conditions.</li>
                                    </ul>

                                    <br>
                                    If you make a request, we have one month to respond to you. If you would like to
                                    exercise any of these rights, please contact us.
                                    </p>

                                    <h2 style="color: white; font-weight: 600; text-align: left;" class="mb-5">Children's
                                        Information</h2>
                                    <p style="color: white; text-align: justify;">
                                        Another part of our priority is adding protection for children while using the
                                        internet. We encourage parents and guardians to observe, participate in, and/or
                                        monitor and guide their online activity.
                                        ChatPro AI does not knowingly collect any Personal Identifiable Information from
                                        children under the age of 13. If you think that your child provided this kind of
                                        information on our website, we strongly encourage you to contact us immediately and
                                        we will do our best efforts to promptly remove such information from our records.
                                    </p>
                                    <h2 style="color: white; font-weight: 600; text-align: left;" class="mb-5">Training Data
                                        for AI Assistants</h2>
                                    <p style="color: white; text-align: justify;">
                                        At ChatPro AI, we securely store the information you upload to our web application
                                        in Google Cloud Storage Buckets on the Google Cloud Platform (GCP). This information
                                        is retained until you decide to delete it. We do not use the data for any purpose
                                        other than creating embeddings, which enable our software to provide context to the
                                        LLM model being used at that time. The data is processed by OpenAI through its API,
                                        and it's important to note that OpenAI explicitly states in its terms that they do
                                        not use the information sent through the API to train models. Furthermore, they
                                        ensure that the data is processed securely. You can find more information about the
                                        privacy of your data at this link: <a href="https://openai.com/enterprise-privacy."
                                            style="color: #ffffff;">https://openai.com/enterprise-privacy.</a> We do not
                                        engage in any other use of the data.
                                        <br>
                                        <br>
                                        ChatPro AI is not responsible for the copyrights over the data that the users upload and use for train their own AI Assistants, we encourage the users to use their own or public data.
                                    </p>
                                    <h2 style="color: white; font-weight: 600; text-align: left;" class="mb-5">Changes to
                                        This Privacy Policy</h2>
                                    <p style="color: white; text-align: justify;">
                                        We may update our Privacy Policy from time to time. Thus, we advise you to review
                                        this page periodically for any changes. We will notify you of any changes by posting
                                        the new Privacy Policy on this page. These changes are effective immediately, after
                                        they are posted on this page.
                                    </p>
                                    <h2 style="color: white; font-weight: 600; text-align: left;" class="mb-5">Contact Us
                                    </h2>
                                    <p style="color: white; text-align: justify;">
                                        If you have any questions or suggestions about our Privacy Policy, do not hesitate
                                        to contact us.
                                    </p>
                                </v-col>
                            </v-row>

                        </v-card>
                    </center>
                </v-container>

            </v-main>
            <Footer />

        </v-app>
    </div>
</template>

<script>
import Header from '@/components/components_landing_home/headerHome.vue'
import Footer from '@/components/components_landing_home/Footer.vue'

// @ is an alias to /src
export default {
    name: 'termsView',
    components: {
        Header,
        Footer
    },
}
</script>

<style scoped>
.v-btn {
    text-transform: none;
}

.intro-text-head {
    color: #ffffff;
    font-size: 40px;
    line-height: 65px;
    text-align: center;
    font-weight: 600;
}

.intro-text-head-mb {
    color: #ffffff;
    font-size: 37px;
    line-height: 45px;
    font-weight: 600;

}

.intro-text-head-desc {
    color: white;
    font-size: 18px;
    font-weight: 400;
}

.intro-text-head-desc-mb {
    color: white;
    font-size: 15px;
    font-weight: 400;
    text-align: center;
}

.glass-card {
    border: none;
    background-color: rgba(255, 255, 255, 0.3);
}

.glass-card::before {
    content: "";
    position: absolute;
    top: -2px;
    left: -2px;
    right: -2px;
    bottom: -2px;
    border-image: linear-gradient(45deg, rgb(255, 255, 255), black) 1;
    border-radius: 20px;
}
</style>