<template>
    <div class="mt-15 mb-5">
        <br>
        <h2 class="hidden-sm-and-down" style="color: white; font-size: 35px; font-weight: 600; text-align: center;">Join Our Diverse Global Users</h2>
        <h2 class="hidden-md-and-up" style="color: white; font-size: 28px; font-weight: 600; text-align: center;">Join Our Diverse Global Users</h2>
        <br>
        <v-row class="hidden-sm-and-down" justify="center">
            <v-col v-for="(image, index) in images" :key="index" cols="auto">
                <v-img :src="image" width="70" height="70"></v-img>
            </v-col>
        </v-row>

        <v-row justify="center" class="hidden-md-and-up">
            <v-col v-for="(image, index) in images" :key="index" cols="auto">
                <v-img :src="image" width="45" height="45"></v-img>
            </v-col>
        </v-row>
    </div>
</template>
  
<script>
export default {
    data() {
        return {
            images: [
                'https://d153chakadu90d.cloudfront.net/web/AUS.png',
                'https://d153chakadu90d.cloudfront.net/web/ZL.png',
                'https://d153chakadu90d.cloudfront.net/web/VEN.png',
                'https://d153chakadu90d.cloudfront.net/web/HON.png',
                'https://d153chakadu90d.cloudfront.net/web/MX.png',
                'https://d153chakadu90d.cloudfront.net/web/ARG.png',
                'https://d153chakadu90d.cloudfront.net/web/CL.png',
                'https://d153chakadu90d.cloudfront.net/web/UK.png',
                'https://d153chakadu90d.cloudfront.net/web/SP.png',
                'https://d153chakadu90d.cloudfront.net/web/EC.png',
                'https://d153chakadu90d.cloudfront.net/web/PAN.png',
                'https://d153chakadu90d.cloudfront.net/web/LUX.png',
                'https://d153chakadu90d.cloudfront.net/web/BOL.png',
                'https://d153chakadu90d.cloudfront.net/web/PER.png',
                'https://d153chakadu90d.cloudfront.net/web/USA.png',
                'https://d153chakadu90d.cloudfront.net/web/CRO.png',
                'https://d153chakadu90d.cloudfront.net/web/COL.png'
            ],
        };
    }
};
</script>
  