<template>
    <div>
        <v-container style="margin-top: 100px; margin-bottom: 30px; text-align: center;">
            <center v-if="!successPayment && !freeListingFlag">
                <p class="intro-text-head-desc mt-5">Submit your GPTs.</p>
                <p><b>💸 Make money with your GPTs</b> we help you promote your GPTs with <span
                        style="background-color: #7579E7; color: white; border-radius: 5px;" class="mx-1"><b>+7.000
                        </b></span>people</p>
                <p><b>Easy marketing</b> for your GPTs</p>
            </center>
            <v-card class="elevation-3 mx-auto" style="width: 550px; border-radius: 15px;">
                <v-form v-if="!payStep && !successPayment && !freeListingFlag">
                    <br>
                    <div class="mx-5">
                        <p style="color:black; text-align: left"><b>Email</b></p>
                        <p style="color:black; text-align: left; font-size: smaller;">📩 We will notify you when your GPT is
                            listed</p>
                        <v-text-field color="black" v-model="Email" type="email" required outlined dense
                            label="user@example.com"></v-text-field>
                        <p style="color:black; text-align: left"><b>OpenAI GPT URL</b></p>
                        <v-text-field color="black" v-model="GPTurl" type="text" required outlined dense
                            label="https://chat.openai.com/g/g-2fkFE8rbu-dall-e"></v-text-field>
                        <v-col style="text-align: right;">
                            <v-btn class="ma-2 white--text" rounded color="black" @click="postGPT()"
                                :disabled="GPTurl === '' || Email === '' || !isValidEmail || !isValidURL || loadingNext"
                                :loading="loadingNext">Next<v-icon right>mdi-chevron-right</v-icon></v-btn>
                        </v-col>
                    </div>
                </v-form>
            </v-card>
            <div v-for="(plan, index) in plans" :key="index" class="mx-auto">
                <v-card style="width: 500px; border-radius: 15px;" v-if="payStep && !successPayment && !freeListingFlag" class="mx-auto">
                    <br>
                    <h1 class="ml-7" style="font-weight: 800; font-size: 40px; text-align: left;"><span class="mr-2"
                            style="font-weight: 500; color: rgb(184, 184, 184);"><s>$11</s></span>{{ plan.price }}<span
                            style="font-size: 14px; font-weight: 400;">One Time payment</span>
                    </h1>
                    <div style="text-align: left;">
                        <v-btn x-small outlined rounded color="#7579e7" class="ml-7">-$4 USD for our Product Hunt
                            Launch</v-btn>
                    </div>

                    <v-list-item three-line class="mb-4">
                        <v-list-item-content class="ml-2" style="text-align: left;">
                            <v-list-item-subtitle class="mt-2" v-for="(feature, fIndex) in plan.features" :key="fIndex">
                                <v-icon left size="15" color="black">mdi-checkbox-marked-circle-outline</v-icon>
                                <span style="font-size: small;">{{ feature }}</span>
                            </v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>
                    <v-card-actions>
                        <v-btn @click="buyProduct()" rounded color="black" block class="white--text mt-3"
                            :loading="loadingPay">Submit<v-icon right color="white">mdi-creation</v-icon></v-btn>
                    </v-card-actions>

                    <v-btn @click="freeListing()" rounded color="black" small outlined class="mt-3 mb-3 mx-5"
                        :loading="loadingPay">Just Free listing</v-btn>
                </v-card>
            </div>

            <div v-if="successPayment">
                <h2 v-if="successPayment" class="mb-6 ml-8 mr-8"
                    style=" padding: 90px 0px 0px; text-align:center; color: #25D366; font-size: xx-large; font-weight: 500;">
                    <v-icon color="#25D366" class="mr-2">mdi-check-circle</v-icon>Successful Payment!
                </h2>
                <p>📩 We will notify you to this email when your GPT is listed:</p>
                <v-btn elevation="0" large>{{ this.Email }}</v-btn>
            </div>

            <div v-if="freeListingFlag">
                <h2 v-if="freeListingFlag" class="mb-6 ml-8 mr-8"
                    style=" padding: 90px 0px 0px; text-align:center; color: #25D366; font-size: xx-large; font-weight: 500;">
                    <v-icon color="#25D366" class="mr-2">mdi-check-circle</v-icon>Successful submit!
                </h2>
                <p>📩 We will notify you to this email when your GPT is listed:</p>
                <v-btn elevation="0" large>{{ this.Email }}</v-btn>
            </div>
        </v-container>
    </div>
</template>

<script>

import Vue from 'vue';
import VueToast from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-sugar.css';
import axiosGPTs from "@/logic/ProGPTs/users/axios"

Vue.use(VueToast);

export default {
    name: 'HomeView',
    components: {
    },
    data() {
        return {
            Email: '',
            GPTurl: '',
            gpts: [{
                _id: "asdfadsfa65ad",
                logo: "https://files.oaiusercontent.com/file-SxYQO0Fq1ZkPagkFtg67DRVb?se=2123-10-12T23%3A57%3A32Z&sp=r&sv=2021-08-06&sr=b&rscc=max-age%3D31536000%2C%20immutable&rscd=attachment%3B%20filename%3Dagent_3.webp&sig=pLlQh8oUktqQzhM09SDDxn5aakqFuM2FAPptuA0mbqc%3D",
                name: "DALL·E",
                description: "Let me turn your imagination into imagery",
                createdAt: "2023-11-10",
                url: "https://chat.openai.com/g/g-2fkFE8rbu-dall-e",
                creator: ""
            },
            {
                _id: "asdf5adsffsa65ad",
                logo: "https://files.oaiusercontent.com/file-MjvVb8L9Se5PdSC1gMLopCHh?se=2123-10-13T00%3A50%3A51Z&sp=r&sv=2021-08-06&sr=b&rscc=max-age%3D31536000%2C%20immutable&rscd=attachment%3B%20filename%3Dnegotiator.png&sig=GDq28k4lIHIZbvXfm9PjQerwO1kGUnkNn6a5aQD/7/M%3D",
                name: "The Negotiator",
                description: "I'll help you advocate for yourself and get better outcomes. Become a great negotiator.",
                createdAt: "2023-11-10",
                url: "https://chat.openai.com/g/g-TTTAK9GuS-the-negotiator",
                creator: "",
                tag: "productivity",
                email_creator: "a@openai.com"
            },
            {
                _id: "asdf5ads3fsfa65ad",
                logo: "https://files.oaiusercontent.com/file-KSheuuQR8UjcxzFjjSfjfEOP?se=2123-10-13T00%3A52%3A56Z&sp=r&sv=2021-08-06&sr=b&rscc=max-age%3D31536000%2C%20immutable&rscd=attachment%3B%20filename%3Dcreative-writing.png&sig=MA3AFe4yhExdlgBje00y4%2BCLHpBkJ%2BUQKkiwknp46as%3D",
                name: "Creative Writing Coach",
                description: "I'm eager to read your work and give you feedback to improve your skills.",
                createdAt: "2023-11-10",
                url: "https://chat.openai.com/g/g-lN1gKFnvL-creative-writing-coach",
                creator: ""
            },
            {
                _id: "asdf5adsfsfa65ad",
                logo: "https://files.oaiusercontent.com/file-6qPLxHx8u9lf2XszRtWG25RD?se=2123-10-14T00%3A38%3A41Z&sp=r&sv=2021-08-06&sr=b&rscc=max-age%3D31536000%2C%20immutable&rscd=attachment%3B%20filename%3Dlogo.png&sig=LV3ZLlRkK4S61JLukf%2B10WLwU4%2B7%2B6NNkTLm2gsbw%2BU%3D",
                name: "Canva",
                description: "Effortlessly design anything: presentations, logos, social media posts and more.",
                createdAt: "2023-11-10",
                url: "https://chat.openai.com/g/g-alKfVrz9K-canva",
                creator: ""
            },
            {
                _id: "asdf5adss74fa65ad",
                logo: "https://files.oaiusercontent.com/file-wOlP7l6RXZJ4YvZq3pUAqLOY?se=2123-10-13T04%3A59%3A43Z&sp=r&sv=2021-08-06&sr=b&rscc=max-age%3D31536000%2C%20immutable&rscd=attachment%3B%20filename%3DHotMods.png&sig=eayebboHdkASOIpNoSeDCqC9o5/XYCmjaV/oYy2%2BsmQ%3D",
                name: "Hot Mods",
                description: "Let's modify your image into something really wild. Upload an image and let's go!",
                createdAt: "2023-11-10",
                url: "https://chat.openai.com/g/g-fTA4FQ7wj-hot-mods",
                creator: ""
            },
            {
                _id: "asdf5adss74fa65ad",
                logo: "https://files.oaiusercontent.com/file-mNWQ25IS4qrOhTcb8ckt53tC?se=2123-10-16T00%3A25%3A00Z&sp=r&sv=2021-08-06&sr=b&rscc=max-age%3D31536000%2C%20immutable&rscd=attachment%3B%20filename%3Dlogo-1024px.png&sig=6/cbaCweksm0UlNkRh16wG4WfmpJa4LeaXYLD5O/PCo%3D",
                name: "NomadGPT",
                description: "NomadGPT helps you become a digital nomad and find you the best places in the world to live and work remotely.",
                createdAt: "2023-11-10",
                url: "https://chat.openai.com/g/g-0k9rvxdJn-nomadgpt",
                creator: ""
            }],
            plans: [
                {
                    title: 'Submit',
                    price: '$7',
                    features: [
                        'Promote to over 7,000 users from ChatPro AI and Pill Startup across various countries.',
                        'Featured tag in proGPTs.',
                        'Featured in proGPTs Newsletter.'
                    ]
                }
            ],
            payStep: false,
            loadingNext: false,
            loadingPay: false,
            successPayment: false,
            freeListingFlag: false
        };
    },
    computed: {
        isValidEmail() {
            // Expresión regular para validar el correo electrónico
            const emailRegex = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
            return emailRegex.test(this.Email);
        },

        isValidURL() {
            // Expresión regular para validar el url
            const urlGPTregex = /^https:\/\/chat\.openai\.com.*/;
            return urlGPTregex.test(this.GPTurl);
        }
    },
    methods: {
        freeListing() { 
            this.freeListingFlag = true
            setTimeout(this.redirectToLogin, 4000);
            console.log('Thanks for your submit.');
        },
        goToSubmit() {
            window.location.href = "https://chatpro.pro/gpts"; //redirecciona a chatpro register
        },

        goToGPT(url) {
            window.open(url, '_blank');
        },

        searchGpts() {
            // Filtra los gpts según el término de búsqueda
            this.filteredGpts = this.gpts.filter(gpt => {
                // Puedes ajustar las propiedades que deseas incluir en la búsqueda
                return (
                    gpt.name.toLowerCase().includes(this.searchTerm.toLowerCase()) ||
                    gpt.description.toLowerCase().includes(this.searchTerm.toLowerCase())
                );
            });
        },

        async postGPT() {
            this.loadingNext = true // se enciende el loader del boton next
            console.log(this.Email, this.GPTurl)
            const register_basic_user = await axiosGPTs.register_basic_user(this.Email, this.GPTurl)
            // console.log(register_basic_user.data)

            if (register_basic_user.data.code == 'success') {
                //cuando el post sea exitoso
                this.payStep = true // se muestra la card de pago
                this.loadingNext = true // se apaga el loader del boton next
            } else {
                console.log("Error al registrar user")
            }
        },

        redirectToLogin() {
            window.location.href = 'https://progpts.pro';
        },

        checkoutComplete() {
            this.successPayment = true; //Enciende el texto de Pago Exitoso
            setTimeout(this.redirectToLogin, 4000);
            console.log('Thanks for your purchase.');
        },

        checkoutClosed(data) {
            console.log(data);
            Vue.$toast.open({
                message: '<p style="font-family: poppins;">Your purchase has been cancelled, try again.</p>',
                type: 'warning',
                position: 'top',
                duration: 4000,
            });
        },

        buyProduct() {
            console.log("Starter Plan");

            //Paddle CheckOut Overlay
            // eslint-disable-next-line
            Paddle.Checkout.open({
                product: 860683, //
                email: this.Email,
                successCallback: this.checkoutComplete, //llama a la función checkoutComplete cuando el pago fue exitoso
                closeCallback: this.checkoutClosed //llama a la función checkoutClosed cuando el pago NO fue exitoso
            });
        }

    }
}
</script>

<style scoped>
.v-btn {
    text-transform: none;
}

.intro-text-head-desc {
    color: rgb(0, 0, 0);
    font-size: 2rem;
    font-weight: 550;
}


.custom-card:hover {
    /* Aplica el estilo al pasar el mouse por encima */
    background-color: #e0e0e0;
    /* Cambia el color de fondo al pasar el mouse por encima */
}
</style>

