<template>
    <!-- If using vue-router -->
    <router-view>
      <router-view />
    </router-view>
</template>
<script>
export default {
  name: 'App',
  mounted() {
    const script = document.createElement('script');
    script.src = 'https://d153chakadu90d.cloudfront.net/web/chat.js';
    script.id = 'sd4JXnaHzO9YpHeoWQPn';
    document.body.appendChild(script);
  },
}
</script>