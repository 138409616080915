<template>
    <div>
        <v-app>
            <Header />
            <v-main
                style="padding: 0px 0px 0px; background-image: linear-gradient(to bottom, #000000, #000000,  #000000, #000000,  #636363, #ffffff);">
                <v-container style="margin-top: 130px; margin-bottom: 200px; text-align: center;">
                    <center>
                        <h2 class="intro-text-head hidden-sm-and-down">Try one of our AI Assistants demo</h2>
                        <h2 class="intro-text-head-mb hidden-md-and-up">Try one of our AI Assistants demo</h2>

                        <p class="intro-text-head-desc mt-2 hidden-sm-and-down">Create custom AI Assistants with your own
                            content, start now for Free.</p>
                        <p class="intro-text-head-desc-mb mt-8 hidden-md-and-up ml-2 mr-2" style="text-align: justify;">
                            Create custom AI Assistants with your own content, start now for Free.</p>

                        <br>
                        <v-btn @click="goToRegister()" rounded color="white">
                            Create yours for FREE
                            <v-icon right>mdi-creation</v-icon>
                        </v-btn>
                        <br>
                        <br>
                        <br>
                        <iframe src="https://ai.chatpro.pro/8mKkq4iRcJcXATuVSqAD"
                            style="border:0px #ffffff hidden; border-radius: 20px;" name="ChatPro" scrolling="yes"
                            class="hidden-sm-and-down" frameborder="0" marginheight="0px" marginwidth="0px" height="600px"
                            width="850px" allowfullscreen></iframe>


                        <iframe src="https://ai.chatpro.pro/8mKkq4iRcJcXATuVSqAD"
                            style="border:0px #ffffff hidden; border-radius: 20px;" class="hidden-md-and-up" name="ChatPro"
                            scrolling="yes" frameborder="0" marginheight="0px" marginwidth="0px" height="700px"
                            width="400px" allowfullscreen></iframe>
                        <tags1 />
                        <!-- <howItWorks /> -->



                    </center>
                </v-container>

            </v-main>
            <Footer />

        </v-app>
    </div>
</template>

<script>
import Header from '@/components/components_landing_home/headerHome.vue'
import Footer from '@/components/components_landing_home/Footer.vue'
import tags1 from '@/components/components_landing_home/tags1.vue'
// import howItWorks from '@/components/components_landing_home/howItWorks.vue'
// import Assistant from '@/components/components_landing_home/customAsisstant.vue'

// @ is an alias to /src
export default {
    name: 'termsView',
    components: {
        Header,
        Footer,
        tags1,
        // howItWorks,
        // Assistant
    },
    methods: {
        goToRegister() {
            this.$router.push("/Register")
        }
    }
}
</script>

<style scoped>
.v-btn {
    text-transform: none;
}

.intro-text-head {
    color: #ffffff;
    font-size: 40px;
    line-height: 65px;
    text-align: center;
    font-weight: 600;
}

.intro-text-head-mb {
    color: #ffffff;
    font-size: 37px;
    line-height: 45px;
    font-weight: 600;

}

.intro-text-head-desc {
    color: white;
    font-size: 18px;
    font-weight: 400;
}

.intro-text-head-desc-mb {
    color: white;
    font-size: 15px;
    font-weight: 400;
    text-align: center;
}
</style>