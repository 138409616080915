
<template>
    <div class="video-wrapper">
        <video playsinline autoplay muted loop>
            <source src="https://d153chakadu90d.cloudfront.net/web/bg_2.webm" type="video/webm">
            <source src="https://d153chakadu90d.cloudfront.net/web/bg_2.mp4" type="video/mp4">
            Your browser does not support the video tag.
        </video>
        <div class="header hidden-sm-and-down">
            <center>
                <h2 class="intro-text-head" style="width: 55%;">Create <u>ChatGPT</u> Assistants for your website.</h2>
                <v-btn class="ma-5 white--text" rounded elevation="0" color="#6C4FFA"><v-icon color="white"
                        left>mdi-creation</v-icon>AI but trained with your own content.</v-btn>
                <p class="intro-text-head-desc mt-5" style="width: 60%;">Just connect your data sources and get a
                        ChatGPT chatbot for your data. Then add a chat widget to your site or share a link.</p>

                <v-btn @click="goToRegister()" rounded color="white" class="mt-8">
                    <span style="font-weight: 500;">Start now <b>FREE</b></span> <v-icon right>mdi-chevron-right</v-icon>
                </v-btn>
            </center>
        </div>
        <div class="header hidden-md-and-up">
            <center>
                <h2 class="intro-text-head-mb">Create <u>ChatGPT</u></h2>
                <h2 class="intro-text-head-mb mb-3">Assistants for your website.</h2>
                <v-btn class="ma-4 white--text" rounded elevation="0" color="#6C4FFA"><v-icon color="white"
                        left>mdi-creation</v-icon>AI but trained with your own content.</v-btn>
                <p class="intro-text-head-desc-mb mt-4" style="width: 80%;">Just connect your data sources and get a ChatGPT chatbot for
                        your data. Then add a chat widget to your site or share a link.</p>

                <v-btn @click="goToRegister()" rounded color="white" class="mt-8">
                    <span style="font-weight: 500;">Start now <b>FREE</b></span> <v-icon right>mdi-chevron-right</v-icon>
                </v-btn>
            </center>
        </div>
    </div>
</template>
<script>

// @ is an alias to /src  

export default {
    name: 'HomeView',
    components: {
    },
    methods: {
        goToRegister() {
            this.$router.push("/Register") //redirecciona a chatpro register
        },
    }
}
</script>

<style scoped>
/* Style to make the video cover the entire component */
.header {
    position: relative;
    color: white;
    text-shadow: 1px 1px 8px rgba(0, 0, 0, 0.6);
}

/* Style for the content overlaying the video */
video {
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
}

.video-wrapper {
    width: 100%;
    height: 800px;
    position: relative;
    overflow: hidden;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: black;
}

.v-btn {
    text-transform: none;
}

.intro-text-head {
    color: #ffffff;
    font-size: 50px;
    line-height: 65px;
    text-align: center;
    font-weight: 650;
}

.intro-text-head-mb {
    color: #ffffff;
    font-size: 37px;
    line-height: 45px;
    font-weight: 600;

}

.intro-text-head-desc {
    color: white;
    font-size: 20px;
    font-weight: 400;
}

.intro-text-head-mb2 {
    color: black;
    font-size: 20px;
    font-weight: 400;
}

.intro-text-head-desc-mb {
    color: white;
    font-size: 15px;
    font-weight: 400;
    text-align: center;
}
</style>


