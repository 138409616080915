import axios from "axios";
// import authFirebase from "../../authFirebase";
const {ENDPOINT_PROGPTS} = require("@/logic/globalEndpoints")


export default {


    //++++++++++++++++ Metodo POST para REGISTRAR UN NUEVO USUARIO A CHATPRO +++++++++++++++++++++++++
    async register_basic_user(email_creator, url_gpt) {

        const new_register_basic_model = {
            email_creator: email_creator,
            url_gpt: url_gpt
        };

        const resp = await axios.post(ENDPOINT_PROGPTS + "/register-basic-gpts", new_register_basic_model)
        return resp;
    },

    //++++++++++++++++ Metodo GET para TRAER Info del USUARIO +++++++++++++++++++++++++
    // async get_user_data(id_user) {

    //     const params = {
    //         id_user: id_user,
    //     };

    //     const headers = {
    //         Authorization: 'Bearer ' + await authFirebase.createTokenJWT()
    //     };

    //     const resp = await axios.get(ENDPOINT_API_USERS + "/api/b2c/data-user", { params, headers })
    //     return resp;
    // },

    


}