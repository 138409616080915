<template>
    <div>
        <v-app>
            <Header />
            <v-main style="padding: 0px 0px 0px;">
                <formSubmitGPTs />
            </v-main>
            <Footer />
        </v-app>
    </div>
</template>
  
<script>
// @ is an alias to /src
import Header from '@/components/components_landing_home/headerHomeGPTs.vue'
import Footer from '@/components/components_landing_home/FooterGPTs.vue'
import formSubmitGPTs from '@/components/components_landing_home/formSubmitGPTs.vue'


export default {
    name: 'HomeView',
    components: {
        Header,
        Footer,
        formSubmitGPTs
    },
}
</script>
  
  
  
  